import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = { class: "container" };
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "chat-card-icon"),
            _renderSlot(_ctx.$slots, "chat-card-title"),
            (_ctx.enabled)
                ? (_openBlock(), _createElementBlock("spk-text", {
                    key: 0,
                    type: "p",
                    "data-test": "typical-response-time",
                    innerHTML: _ctx.typicalResponseTimeTranslation
                }, null, 8 /* PROPS */, _hoisted_2))
                : (_openBlock(), _createElementBlock("spk-text", {
                    key: 1,
                    type: "p",
                    "data-test": "chat-not-available",
                    innerHTML: _ctx.chatNotAvailableTranslation
                }, null, 8 /* PROPS */, _hoisted_3)),
            _createElementVNode("spk-text", {
                type: "p",
                "data-test": "chat-hours",
                innerHTML: _ctx.chatHoursTranslation
            }, null, 8 /* PROPS */, _hoisted_4)
        ]),
        (_ctx.enabled)
            ? _renderSlot(_ctx.$slots, "chat-card-start-chat-button", { key: 0 })
            : _createCommentVNode("v-if", true)
    ], 64 /* STABLE_FRAGMENT */));
}
